<template>
	<div >
		
		<div class="ft20 cl-black cl-main ftw500">员工合伙人</div>
		
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 600px;">
				<div class="flex alcenter">
					<a-button type="primary" icon="plus" @click="addEmployeePartnerAct()">添加员工合伙人</a-button>
				</div>
				
				<div class="mt10">
					<div class="form-search-box ">
						<a-form layout="inline">
							<a-form-item label="合伙人手机号">
								<a-input v-model="search.mobile" placeholder="请输入合伙人手机号"></a-input>
							</a-form-item>
							
							<a-form-item>
								<a-button @click="searchAct" type="primary">查询</a-button>
								<a-button @click="cancelAct" class="ml10">取消</a-button>
							</a-form-item>
						</a-form>
					</div>
				</div>
				
				
				
				<div class="mt30">
					<div class="wxb-table-gray">
						<a-table rowKey="partner_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							
							
							
							
							<div class="flex alcenter center" slot="profit" slot-scope="profit,record">
								{{profit}}元
							</div>
							
							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;"  @click="toDetail(record)">
													<i class="iconfont ft14 iconsee"></i>
													<span class="ml10">查看</span>
												</a>
											  </a-menu-item>
											  <a-menu-item>
													<a class="menu-act" href="javascript:;"  @click="editEmployeePartnerAct(record)">
														<i class="iconfont ft14 iconedit"></i>
														<span class="ml10">编辑</span>
													</a>
											  </a-menu-item>
											  <a-menu-item>
													<a class="menu-act" href="javascript:;" @click="del(record)">
														<i class="iconfont ft14 icondelete"></i>
														<span class="ml10">删除</span>
													</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			
				<div v-if="editEmployeePartnerLoading">
					<edit-employee-partner :partner_id="partner_id" :visible="editEmployeePartnerLoading" @cancel="cancelEditEmployeePartner" @ok="okEditEmployeePartner"></edit-employee-partner>
				</div>
			</div>
		</div>
	</div>
				
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import editEmployeePartner from './components/EmployeePartner/modal/editEmployeePartner.vue';
	export default {
		mixins: [listMixin],
		components:{
			editEmployeePartner,
		},
		data() {
			return {
				loading: false,
				editEmployeePartnerLoading:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				partner_id:0,
				search: {
					mobile: '',
				},
				columns: [
					{title: '员工姓名',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '业绩收益',dataIndex: 'profit',align: 'center',scopedSlots: {customRender: 'profit'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods: {
			
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getPartnerList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					mobile: this.search.mobile,
					type:2,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
			
			del(record){
				this.$confirm({
					title:'确认删除这个合伙人吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delPartner',{
								partner_id:record.partner_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			addEmployeePartnerAct(){
				this.partner_id=0;
				this.editEmployeePartnerLoading=true;
			},
			editEmployeePartnerAct(record){
				this.partner_id=record.partner_id;
				this.editEmployeePartnerLoading=true;
			},
			cancelEditEmployeePartner(){
				this.editEmployeePartnerLoading=false;
			},
			okEditEmployeePartner(){
				this.editEmployeePartnerLoading=false;
				this.getLists();
			},
			
			toDetail(record){
				this.$router.push('/partner/employeePartner/detail?id='+record.partner_id)
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
		}
	}
</script>

<style>
	
</style>
